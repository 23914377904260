import React from 'react';
import './Modal.css';

export default function Modal({ title, content, onClose }) {
    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal">
                <h2>{title}</h2>
                <div>{content}</div>
                <button onClick={onClose}>닫기</button>
            </div>
        </>
    );
}
