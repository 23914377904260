import './Footer.css'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons' // 인스타그램 아이콘 추가
// 네이버 블로그 아이콘을 직접 넣거나 대체 아이콘 사용 (아이콘 파일이 있을 경우)
import { faBlogger } from '@fortawesome/free-brands-svg-icons' // 네이버 블로그 대신 사용 (아이콘 직접 구할 경우 교체)

import Modal from './Modal'

export default function Footer() {
  const [isTermsModalOpen, setTermsModalOpen] = useState(false)
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false)

  const openTermsModal = () => setTermsModalOpen(true)
  const closeTermsModal = () => setTermsModalOpen(false)
  const openPrivacyModal = () => setPrivacyModalOpen(true)
  const closePrivacyModal = () => setPrivacyModalOpen(false)

  return (
    <footer className='footer__outer'>
      <div className='max-container footer'>
        <div className='footer-two-one-container'>
          <div>
            <img
              className='footer__logo'
              src={process.env.PUBLIC_URL + '/logo-text-black.png'}
              alt='logo'
            />
          </div>
          <div>
            <ul className='footer__menu' style={{ paddingBottom: '1rem' }}>
              <li>
                <button
                  className='sub'
                  onClick={openTermsModal}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  이용약관
                </button>
              </li>
              <li>
                <button
                  className='sub'
                  onClick={openPrivacyModal}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <b>개인정보처리방침</b>
                </button>
              </li>
              <li>
                <a
                  href='mailto:ssaksri23@gmail.com?subject=제휴 문의'
                  className='sub'
                  style={{ cursor: 'pointer' }}
                >
                  제휴 문의
                </a>
              </li>
              <li>
                <a
                  href='mailto:ssaksri23@gmail.com?subject=IR 문의'
                  className='sub'
                  style={{ cursor: 'pointer' }}
                >
                  IR 문의
                </a>
              </li>
            </ul>
            <p className='sub' style={{ paddingBottom: '0.5rem' }}>
              <b>주소</b> 광주 광역시 서구 하남대로 680번길 1, 4층
            </p>
            <p className='sub' style={{ paddingBottom: '2rem' }}>
              <b>사업자번호</b> 110-22-53859 | <b>통신판매업 신고번호</b> 제
              2024-광주서구-0681 호
            </p>
            <p className='sub' style={{ paddingBottom: '0.5rem' }}>
              Copyright <b>리치(Rich)</b> Corp. LTD ALL RIGHT RESERVED.
            </p>
          </div>
        </div>

        <div className='footer-two-two-container'>
          <h3 style={{ paddingBottom: '0.5rem' }}>고객센터</h3>
          <h2 style={{ paddingBottom: '2rem' }}>
            <a className='footer__phone' href='tel:0507-1319-0641'>
              0507-1319-0641
            </a>
          </h2>

          <p className='sub' style={{ paddingBottom: '0.5rem' }}>
            <b>연중무휴 오전 9시 ~ 오후 6시</b>
          </p>
          <p className='sub' style={{ paddingBottom: '1rem' }}>
            (점심시간 12:30 ~ 13:30)
          </p>
          <p className='sub' style={{ paddingBottom: '0.5rem' }}>
            이사 견적은 신청서 작성을 통해서만 신청이 가능하며,
          </p>
          <p className='sub' style={{ paddingBottom: '2rem' }}>
            서비스 관련 문의는 고객센터를 통해 상담부탁드립니다.
          </p>

          <ul className='footer__menu'>
            <li>
              <a
                href='https://www.instagram.com/goldmove.co.kr'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon className='footer__icon' icon={faInstagram} />{' '}
                {/* 인스타그램 아이콘 */}
              </a>
            </li>
            <li>
              <a
                href='https://blog.naver.com/ssaksri23'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon className='footer__icon' icon={faBlogger} />{' '}
                {/* 네이버 블로그 대신 블로거 아이콘 */}
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* 모달 컴포넌트들 */}
      {isTermsModalOpen && (
        <Modal
          title='금빛이사 이용약관'
          content=" 제 1조 (목적)
본 약관은 금빛이사(이하 '회사')가 제공하는 온라인 이사 플랫폼 서비스(이하 '서비스')의 이용과 관련하여 회사와 회원의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2조 (정의)
1. '서비스'라 함은 이용자가 인터넷, 모바일 등 유무선 정보통신 설비를 이용하여 이사 관련 정보 제공, 견적 요청, 예약 등을 할 수 있도록 회사가 제공하는 이사 중개 플랫폼 서비스를 의미합니다.
2. '이용자'라 함은 본 약관에 따라 회사가 제공하는 서비스를 받는 자로, 회원과 비회원을 포함합니다.
3. '회원'이라 함은 회사의 서비스에 개인정보를 제공하고 회원 가입을 한 자로서, 회사가 제공하는 서비스의 지속적인 이용이 가능한 자를 의미합니다.
4. '비회원'이라 함은 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.

제 3조 (약관의 명시, 효력 및 변경)
1. 본 약관은 이용자가 서비스 화면 또는 링크 등을 통하여 이를 알 수 있도록 게시함으로써 그 효력을 발생합니다.
2. 회사는 관련 법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3. 약관이 변경될 경우, 회사는 그 내용을 사전에 공지하며, 이용자가 약관 변경 공지 후에도 계속 서비스를 이용할 경우 변경된 약관에 동의한 것으로 간주됩니다.

제 4조 (서비스의 제공 및 변경)
회사는 다음과 같은 업무를 수행합니다:
1. 이사 서비스 관련 정보 제공
2. 이사 견적 요청 및 이사 업체와의 연결
3. 이사 예약 및 일정 관리
4. 기타 이사 관련 부가 서비스 제공
5. 회사는 서비스의 내용 및 제공 방식을 변경할 수 있으며, 이 경우 이용자에게 사전에 공지합니다.

제 5조 (서비스의 중단)
1. 회사는 천재지변, 전쟁, 정전, 시스템 장애 등 불가항력적인 사유로 서비스 제공이 어려운 경우, 서비스의 일시적 중단 또는 영구 중단할 수 있습니다.
2. 회사는 본 조 제1항에 의한 서비스 중단 시 사전에 이를 공지하며, 사전 공지가 어려운 경우 사후에 이를 통지합니다.

제 6조 (회원 가입)
1. 이용자는 회사가 정한 절차에 따라 회원 가입을 신청할 수 있으며, 회사는 이러한 신청에 대하여 승낙함으로써 회원가입이 성립됩니다.
2. 회원 가입 시 제공된 정보가 허위이거나 타인의 정보를 도용한 경우, 회사는 해당 회원에 대해 서비스 이용을 제한하거나 회원 자격을 박탈할 수 있습니다.

제 7조 (회원 탈퇴 및 자격 상실)
1. 회원은 언제든지 서비스 내 탈퇴 절차를 통해 회원 탈퇴를 요청할 수 있으며, 회사는 즉시 회원 탈퇴 처리를 진행합니다.
2. 회사는 회원이 본 약관을 위반하거나 서비스 운영을 방해한 경우, 회원 자격을 제한 또는 박탈할 수 있습니다.

제 8조 (개인정보의 보호 및 사용)
회사는 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 개인정보 처리방침을 따릅니다.

제 9조 (이용자의 의무)
1. 이용자는 회사가 제공하는 서비스를 이용함에 있어 관계 법령, 본 약관, 이용 안내 등 회사가 공지한 사항을 준수해야 합니다.
2. 이용자는 서비스를 이용함에 있어 다음 행위를 해서는 안 됩니다:
3. 타인의 개인정보를 도용하는 행위
4. 서비스 이용 과정에서 허위 정보를 제공하는 행위
5. 회사의 서비스 운영을 방해하는 행위
6. 불법적인 목적으로 서비스를 이용하는 행위

제 10조 (서비스의 이용 제한)
회사는 이용자가 본 약관을 위반하거나 서비스의 정상적인 운영을 방해한 경우, 사전 통지 없이 서비스 이용을 제한할 수 있으며, 이로 인해 발생한 손해에 대해 책임을 지지 않습니다.

제 11조 (손해배상)
1. 회사는 무료로 제공되는 서비스와 관련하여 이용자에게 발생하는 손해에 대해 어떠한 책임도 지지 않습니다.
3. 유료 서비스의 경우, 회사의 고의 또는 중대한 과실로 인해 발생한 손해에 한하여 이용자가 지불한 금액을 한도로 배상 책임을 집니다.

제 12조 (면책조항)
1. 회사는 서비스 제공 과정에서 발생할 수 있는 데이터 오류, 시스템 문제 등에 대해 책임을 지지 않으며, 이용자는 이를 충분히 이해하고 서비스 이용에 주의해야 합니다.
2. 회사는 회원 간 또는 회원과 제3자 간에 발생한 분쟁에 대해서는 관여하지 않으며, 그로 인한 손해는 해당 당사자 간의 해결을 원칙으로 합니다.

제 13조 (기타)
이 약관에 명시되지 않은 사항에 대해서는 관련 법령 또는 상관례에 따릅니다."
          onClose={closeTermsModal}
        />
      )}
      {isPrivacyModalOpen && (
        <Modal
          title='금빛이사 개인정보 처리방침'
          content="제 1조 (목적)
금빛이사(이하 '회사')는 정보주체의 개인정보를 중요하게 생각하며, 개인정보 보호법 등 관련 법령을 준수하여 정보주체의 개인정보를 보호하기 위한 방침을 마련하고 있습니다. 본 개인정보 처리방침은 회사가 제공하는 이사 중개 서비스와 관련하여 정보주체의 개인정보를 어떻게 수집, 이용, 제공 및 보호하는지에 대해 설명합니다.

제 2조 (수집하는 개인정보의 항목 및 수집 방법)
회사는 다음과 같은 개인정보를 수집할 수 있습니다:
필수 항목: 이름, 연락처, 이메일, 주소, 이사 일정
선택 항목: 추가 요청사항, 결제 정보
개인정보는 다음과 같은 방법으로 수집됩니다:
회원 가입 및 서비스 이용 과정에서 이용자가 자발적으로 제공하는 정보
고객 상담, 이메일, 전화 등을 통해 수집되는 정보

제 3조 (개인정보의 수집 및 이용 목적)
회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다:
서비스 제공: 이사 견적 요청, 예약 및 결제, 고객 문의 응대 등
회원 관리: 회원제 서비스 이용에 따른 본인 확인, 서비스 이용 관련 정보 제공
마케팅 및 광고: 서비스 개선을 위한 통계 분석 및 고객 만족도 조사

제 4조 (개인정보의 보유 및 이용 기간)
회사는 정보주체의 개인정보를 원칙적으로 수집 및 이용 목적이 달성되면 지체 없이 파기합니다.
단, 다음과 같은 경우에는 관련 법령에 따라 일정 기간 동안 개인정보를 보유할 수 있습니다:
계약 또는 청약철회 등에 관한 기록: 5년
대금 결제 및 재화 등의 공급에 관한 기록: 5년
소비자 불만 또는 분쟁 처리에 관한 기록: 3년

제 5조 (개인정보의 제3자 제공)
회사는 원칙적으로 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
다만, 다음과 같은 경우에는 예외로 합니다:
정보주체의 사전 동의를 받은 경우
법령의 규정에 따라 제공할 필요가 있는 경우

제 6조 (개인정보 처리 위탁)
회사는 원활한 업무 처리를 위하여 일부 개인정보 처리를 외부에 위탁할 수 있으며, 위탁 시에는 관련 법령에 따라 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 취합니다.

제 7조 (정보주체의 권리와 행사 방법)
정보주체는 언제든지 자신의 개인정보에 대해 열람, 정정, 삭제, 처리 정지 요청을 할 수 있으며, 이러한 요청은 회사의 고객센터를 통해 가능합니다.

제 8조 (개인정보의 파기 절차 및 방법)
회사는 개인정보의 보유 기간이 경과하거나 처리 목적이 달성된 경우에는 해당 개인정보를 지체 없이 파기합니다.

파기 절차: 이용자가 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 따라 일정 기간 저장된 후 파기됩니다.
파기 방법: 전자적 파일 형태의 정보는 복구할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각합니다.

제 9조 (개인정보 보호를 위한 기술적 및 관리적 대책)
회사는 개인정보의 안전성을 확보하기 위하여 다음과 같은 대책을 강구하고 있습니다:

개인정보 암호화: 이용자의 비밀번호 등 중요한 정보는 암호화되어 저장 및 관리되며, 전송 시에도 별도의 보안 조치가 이루어집니다.
해킹 등에 대비한 대책: 회사는 해킹이나 악성 코드에 의한 정보 유출을 방지하기 위해 보안 프로그램을 설치하고, 주기적인 점검을 시행합니다.

제 10조 (개인정보 보호 책임자 및 담당자)
회사는 개인정보 보호와 관련한 업무를 총괄하는 책임자를 지정하여 운영하고 있으며, 이용자는 개인정보와 관련한 문의를 아래의 연락처로 할 수 있습니다:

이메일: [ssaksri23@gmail.com]

제 11조 (권익침해 구제 방법)
정보주체는 개인정보 침해에 대해 구제를 받기 위해 개인정보 분쟁조정위원회 또는 한국인터넷진흥원에 분쟁 해결 및 상담을 신청할 수 있습니다."
          onClose={closePrivacyModal}
        />
      )}
    </footer>
  )
}
